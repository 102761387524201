<template>
    <v-chip 
        v-if="statusStyle != ''"
        class="table-label"
        small
        label
        :style="statusStyle"
        dark
    >
        <div class="d-flex align-center">
            <v-icon v-if="icon !==''" :color="iconColor">{{ icon }}</v-icon>
            {{$t(statusText)}}
        </div>
    </v-chip>
</template>

<script>
export default {
    props: ['status'],
    computed: {
        statusStyle() {
            return this.status?.style ?? "";
        },
        statusText() {
            return this.status?.text ?? ""
        },
        iconColor() {
            return this.status?.iconColor ?? ""
        },
        icon() {
            return this.status?.icon ?? ""
        }
    }
}
</script>